<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI小红书文案生成" />
                        <ai_switch :select_version="form.select_version" tab1_name="日常版" tab2_name="电商版"
                            @select_versionShow="select_versionShow" />
                        <div v-if="form.select_version == '1'">
                            <div class="form_ct flex">
                                <p class="upLoad_text">主题名称：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.theme_name" placeholder="请录入主题内容。如：小猫日常生活"></el-input>
                                </div>
                            </div>
                            <div class="form_ct flex">
                                <p class="upLoad_text">风格描述：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.style_describe"
                                        placeholder="请录入文案具体风格。如：诙谐幽默、严谨逻辑性强"></el-input>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="form_ct flex">
                                <p class="upLoad_text">文案标题：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.copywriting_title"
                                        placeholder="请录入标题名称。如：红宝石咖啡馆，纵享每一口丝滑！"></el-input>
                                </div>
                            </div>
                            <div class="form_ct flex">
                                <p class="upLoad_text">关键词语：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.keyword"
                                        placeholder="请录入文案包含词。如：店名、地址、产品推荐和环境介绍"></el-input>
                                </div>
                            </div>
                            <ai_select selectTitle="针对行业" :select_list="industry_list" @getSelectVal="get_industry" />
                            <div class="form_ct flex">
                                <p class="upLoad_text">核心词语：</p>
                                <div class="upload_box">
                                    <el-input resize="none" show-word-limit type="textarea" :rows="3"
                                        v-model="form.coreword"
                                        placeholder="请录入文案正文核心关键词。如：浓香拿铁、坚果酸奶碗、哥斯达黎加手冲"></el-input>
                                </div>
                            </div>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_switch from '@/components/ai_switch.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        ai_switch,
        wordNav,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            form: {
                select_version: '1',
                theme_name: '',
                style_describe: '',
                copywriting_title: '',
                keyword: '',
                industry: '',
                coreword: '',
            },
            industry_list: [
                {
                    id: '科技',
                    name: '科技'
                },
                {
                    id: '教育',
                    name: '教育'
                },
                {
                    id: '党政党建',
                    name: '党政党建'
                },
                {
                    id: '餐饮美食',
                    name: '餐饮美食'
                },
                {
                    id: '金融理财',
                    name: '金融理财'
                },
                {
                    id: '体育运动',
                    name: '体育运动'
                },
                {
                    id: '家具装修',
                    name: '家具装修'
                },
                {
                    id: '旅游旅行',
                    name: '旅游旅行'
                },
                {
                    id: '医学医疗',
                    name: '医学医疗'
                },
                {
                    id: '汽车交通',
                    name: '汽车交通'
                },
                {
                    id: '工业',
                    name: '工业'
                },
                {
                    id: '美容',
                    name: '美容'
                },
                {
                    id: '房地产',
                    name: '房地产'
                },
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            type_list: ['工作总结', '工作汇报', '工作计划', '商业融资', '毕业答辩', '述职报告', '个人简介', '自我介绍', '公司简介', '培训课件', '培训课件', '绘本故事'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        select_versionShow(id) {
            this.form = {
                select_version: id,
                theme_name: '',
                style_describe: '',
                copywriting_title: '',
                keyword: '',
                industry: '',
                coreword: '',
            }
        },
        get_industry(val) {
            this.form.industry = val
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        FileData() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            let data = {}
            data.select_version = this.form.select_version
            data.mobile = this.$user_info.mobile
            if (this.form.select_version == '1') {
                data.theme_name = this.form.theme_name
                data.style_describe = this.form.style_describe
            } else {
                data.copywriting_title = this.form.copywriting_title
                data.keyword = this.form.keyword
                data.industry = this.form.industry
                data.coreword = this.form.coreword
            }
            this.curlPost('/file/create_xiaohongshu', data).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>